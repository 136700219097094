import React, { useContext, useEffect } from 'react';
import { getWindow } from '@web-passion/uikit';

import { FormContext } from '../context';

import '../style.css';

import loadComponents from '../../Loadable';
import Country from './country';

const Checkbox = loadComponents('form-checkbox');
const Radio = loadComponents('form-radio');
const Input = loadComponents('form-input');
const TextArea = loadComponents('form-textarea');
const Select = loadComponents('form-select');
const Date = loadComponents('form-date');
const Phone = loadComponents('form-phone');
const Message = loadComponents('form-message');
const Submit = loadComponents('form-submit');

export default function FormFields({ data, application, stage }) {
  const { values, checkCondition, getFieldId, bulkChange } =
    useContext(FormContext);
  const { windowLG } = getWindow();

  const itemId = item => {
    if (item.id) {
      return item.id;
    }
    return item.name && getFieldId(item.name);
  };

  useEffect(() => {
    const selectDefaults = {};

    data
      .filter(x => x.type === 'select')
      .forEach(item => {
        const currentId = itemId(item);

        item.selectOptions.forEach(option => {
          if (option.default && values[currentId] === undefined) {
            selectDefaults[currentId] = option.option;
          }
        });
      });

    bulkChange(selectDefaults);
  }, [stage]);

  return data
    .filter(x => !x.condition || (x.condition && checkCondition(x.condition)))
    .map((item, i) => {
      const key = `field${i}`;
      const isHidden = item.type === 'input' && item.inputType === 'hidden';

      const fieldTitle = item.placeholder ? item.placeholder : item.name;
      const fieldLabel = item.label || item.name;

      const sharedProps = {
        name: itemId(item),
        id: itemId(item),
        label: fieldLabel,
        className: item.class,
        title: fieldTitle,
        'aria-label': fieldTitle,
        required: item.required,
        placeholder: item.placeholder,
        maxLength: item.maxlength,
        value: values[itemId(item)] || '',
        disabled: item.disabled,
      };

      const inputProps = {
        ...sharedProps,
        type: item.inputType,
      };

      const radioProps = {
        ...inputProps,
        options: item.radioOptions,
      };

      const selectProps = {
        ...sharedProps,
        options: item.selectOptions,
      };

      return (
        <div
          key={key}
          className={`field${application ? ` application` : ``}`}
          style={{
            display: isHidden && 'none',
            padding: `0 15px`,
            flex: `0 0 ${
              item.type !== 'message' && windowLG && item.width
                ? item.width
                : `100%`
            }`,
            maxWidth: `100%`,
            margin: `0 0 ${application ? `15px` : `30px`}`,
            position: `relative`,
          }}
        >
          {item.type === 'input' &&
            (() => {
              switch (item.inputType) {
                case 'checkbox':
                  return <Checkbox field={inputProps} />;
                case 'radio':
                  return <Radio field={radioProps} />;
                case 'date':
                  return <Date field={inputProps} />;
                case 'phone':
                  return <Phone field={inputProps} />;
                default:
                  return <Input field={inputProps} />;
              }
            })()}
          {item.type === 'country' && <Country field={sharedProps} />}
          {item.type === 'textarea' && <TextArea field={sharedProps} />}
          {item.type === 'select' && <Select field={selectProps} />}
          {item.type === 'message' && <Message message={item.message} />}
          {item.type === 'submit' && <Submit label={item.buttonLabel} />}
        </div>
      );
    });
}
